var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navigation-v2"},[_c('ul',{staticClass:"unstyled-list"},_vm._l((_vm.items),function(item,itemIndex){return _c('li',[(item.key === 'divider')?_c('hr',{staticClass:"horizontal-divider"}):(item.key === 'home')?[_c('om-button',{class:{ 'is-active': _vm.isHomeActive },attrs:{"ghost":"","block":"","icon":_vm.getIcon(item)},on:{"click":function($event){return _vm.$emit('click', ("" + itemIndex))}}},[_vm._v(_vm._s(_vm.$t(item.name)))])]:[(item.to)?_c('router-link',{attrs:{"tag":"div","to":item.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('om-button',{class:{ 'is-active': isExactActive, 'no-icon': item.iconless },attrs:{"ghost":"","block":"","icon":_vm.getIcon(item, isActive)},on:{"click":function($event){return _vm.emitAndGo(navigate, ("" + itemIndex), $event)}}},[_vm._v(_vm._s(_vm.$t(item.name)))]),(item.children && isActive)?_c('ul',{staticClass:"unstyled-list submenu"},_vm._l((item.children),function(subItem,subItemIndex){return _c('li',[(subItem.to)?_c('router-link',{attrs:{"to":subItem.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('om-button',{class:{ 'navigation-v2-btn--max-width': true, 'is-active': isExactActive || isActive, 'no-icon': subItem.iconless },attrs:{"ghost":"","block":""},on:{"click":function($event){return _vm.emitAndGo(navigate, (itemIndex + "." + subItemIndex), $event)}}},[_vm._v(_vm._s(subItem.name))])]}}],null,true)}):_c('om-button',{class:{ 'no-icon': subItem.iconless },attrs:{"ghost":"","block":"","icon":subItem.icon},on:{"click":function($event){return _vm.emitAndGo(navigate, (itemIndex + "." + subItemIndex), $event)}}},[_vm._v(_vm._s(subItem.name))])],1)}),0):_vm._e()]}}],null,true)}):(item.key === 'new-empty')?_c('div',{staticClass:"from-scratch-button"},[_c('om-button',{attrs:{"ghost":"","block":"","icon":_vm.getIcon(item)},on:{"click":function($event){return _vm.$emit('click', item.key)}}},[_vm._v(_vm._s(item.name))])],1):_c('om-button',{attrs:{"ghost":"","block":"","icon":_vm.getIcon(item)},on:{"click":function($event){return _vm.$emit('click', item.key)}}},[_vm._v(_vm._s(item.name))])]],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }